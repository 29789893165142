// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDhruU5d6Bk5w-96r8vQAwRNaMLMlH504",
  authDomain: "carrentalseoservice.firebaseapp.com",
  projectId: "carrentalseoservice",
  storageBucket: "carrentalseoservice.appspot.com",
  messagingSenderId: "66338648371",
  appId: "1:66338648371:web:d45be84b51dbef9a488e46"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;